<template>
  <div id="app">
    <div class="bg-white">
      <main>
        <NavBar />
        <HeroSection>
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block text-indigo-600">Thoughts</span>
            <span class="block">and Projects </span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            The items presented here are Julien de Charentenay's personal
            thoughts and projects.<br /><br />
            The views, opinions and information expressed in this website are my
            own. This website is provided for information in the hope that it
            will be useful but without any warranty.
          </p>
        </HeroSection>

        <div class="divide-y-2 divide-gray-200">
          <div class="my-12"></div>
          <!-- Thoughts -->
          <div
            class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
          >
            <div class="relative max-w-lg mx-auto lg:max-w-7xl">
              <div id="thoughts">
                <!-- <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"> -->
                <h2
                  class="text-3xl tracking-tight font-extrabold text-indigo-600 sm:text-4xl"
                >
                  Thought, Posts and Interventions
                </h2>
              </div>
              <div
                class="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12"
              >
                <!--
              <ThoughtArticle
                datetime_yyyymmdd="202x-mm-dd"
                datetime_mmmddyyyy="Feb 14, 2021"
                source="xyz.com"
                title=""
                url=""
                >
                INSERT TEXT HERE
              </ThoughtArticle>
-->
              <ThoughtArticle
                datetime_yyyymmdd="2022-06-23"
                datetime_mmmddyyyy="Jun 23, 2022"
                source="medium.com"
                title="Implement an Email Masking Proof-of-concept Using AWS Simple Email Service (SES) and AWS Rust SDK"
                url="https://betterprogramming.pub/implementing-an-email-masking-proof-of-concept-using-aws-simple-email-service-ses-and-aws-rust-c6aa34f1e1df"
                >
This story presents a proof-of-concept that allow me to give a unique email address anything@mydomain.com when subscribing to mailing lists, and registering unimportant accounts. anything can be any word that I decide on the spot when I give the address. The emails are forwarded to my normal email address - and if I find that I am receiving too many, I can unsubscribe and blacklist the address.....
              </ThoughtArticle>
              <ThoughtArticle
                datetime_yyyymmdd="2022-05-09"
                datetime_mmmddyyyy="May 09, 2022"
                source="medium.com"
                title="Implement a Skybox Texture Using Rust and WebGL"
                url="https://betterprogramming.pub/webgl-rust-webassembly-skybox-texture-cd3220bb1ebc"
                >
This story looks at the implementation of a skybox texture using Rust and WebGL for the web viewer for vortex particle simulation described previously. A skybox texture is <em>"a box with textures on it to look like what is very far away including the horizon"</em> (WebGl2Fundamentals WebGL2 SkyBox).
              </ThoughtArticle>
              <ThoughtArticle
                datetime_yyyymmdd="2022-04-10"
                datetime_mmmddyyyy="Apr 10, 2022"
                source="medium.com"
                title="Gamification in Computational Fluid Dynamics in 2022"
                url="https://julien-decharentenay.medium.com/gamification-in-computational-fluid-dynamics-in-2022-5f935b5dd7a2"
                >
This story focuses on <em>"gamification"</em> in the context of Computational Fluid Dynamics, a science focused on predicting the movement of fluids through computer simulation used in the aerospace, automotive and many other industries.
              </ThoughtArticle>
              <ThoughtArticle
                datetime_yyyymmdd="2022-02-21"
                datetime_mmmddyyyy="Feb 21, 2022"
                source="medium.com"
                title="Camera orbit, zoom-in, zoom-out movements in WebGL"
                url="https://blog.devgenius.io/camera-orbit-zoom-in-zoom-out-movements-in-webgl-32cf78e13e42"
                >
The post Implementing a WebAssembly WebGL viewer in Rust describes the implementation of a WebGL viewer using a fixed camera defining viewpoint, view and up directions. This post expands the WebGL viewer to include camera orbit, zoom in and out movements controlled by mouse or touch events.
              </ThoughtArticle>
              <ThoughtArticle
                datetime_yyyymmdd="2022-01-16"
                datetime_mmmddyyyy="Jan 16, 2022"
                source="medium.com"
                title="Rust WebAssembly - Sharing data between WebWorkers"
                url="https://julien-decharentenay.medium.com/rust-webassembly-sharing-data-between-webworkers-f156ba65d141"
                >
                This story presents an improvement to the sharing of data between the web-worker and main threads. The web-worker
                thread runs the fluid simulation algorithm and transfers the simulation results to the main thread. By changing the
                serialization from JSON to binary, a speed-up was achieving allowing the rendering speed to improve from 7fps to 25fps.
              </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-12-29"
                  datetime_mmmddyyyy="Dec 29, 2021"
                  source="medium.com"
                  title="Supporting iOS Safari for a website using MediaPipe ML models, canvas MediaStreams and webm"
                  url="https://julien-decharentenay.medium.com/supporting-ios-safari-for-a-website-using-mediapipe-ml-models-canvas-mediastreams-and-webm-e3423514f7e6"
                >
This story focuses on the implementation and modifications done to video-mash.com to work around technological issues encountered when viewing it using the iOS Safari web-browser. The technologies employed in video-mash.com includes MediaPipe ML model, canvas, MediaStreams and WebM and have been described in previous stories.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-11-13"
                  datetime_mmmddyyyy="Nov 13, 2021"
                  source="medium.com"
                  title="How to save html canvas animation as a video"
                  url="https://julien-decharentenay.medium.com/how-to-save-html-canvas-animation-as-a-video-421157c2203b"
                >
This story show how to add "save to video" capabilities to a webpage containing canvas animation. The implementation extends on the work described in the previous story 'Experimenting with Selfie Segmentation' to add the ability to record the canvas rendering as a video.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-10-11"
                  datetime_mmmddyyyy="Oct 11, 2021"
                  source="medium.com"
                  title="Experimenting with Selfie Segmentation (Teams background effect)"
                  url="https://julien-decharentenay.medium.com/experimenting-with-selfie-segmentation-teams-background-effect-d0810039b4c"
                >
This post shows how to use the MediaPipe Selfie Segmentation model that "segment the prominent humans in the scene". The post shows how to use this model to extract foreground - where the prominent human is - and background - the remaining of the image - from a webcam feed. The live version is presented in the demo page <a href="https://www.video-mash.com/demo.html">https://www.video-mash.com/demo.html</a>.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-08-19"
                  datetime_mmmddyyyy="Aug 19, 2021"
                  source="medium.com"
                  title="Implementing a WebAssembly WebGL viewer using Rust"
                  url="https://julien-decharentenay.medium.com/implementing-a-webassembly-webgl-viewer-using-rust-a6d8a703153d"
                >
This story focuses on the implementation of a web viewer for a fluid solver using the vortex particle method. The fluid solver is written in Rust, and compiles to WebAssembly. A Rust module, handling the WebGL viewer through WebAssembly using web-sys, is the natural next step to allow visualisation of the simulation results in the web-browser. The live version is available at <a href="https://www.cfd-webassembly.com">cfd-webassembly.com</a>.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-07-12"
                  datetime_mmmddyyyy="Jul 12, 2021"
                  source="medium.com"
                  title="Profiling and Optimising a Rust application"
                  url="https://julien-decharentenay.medium.com/profiling-and-optimising-a-rust-application-c3f99e318091"
                >
This story recounts the profiling and optimisation of a Rust application presented previously. Two optimisations are implemented: (a) part code rewrite and (b) compiler flag optimisation. The optimisation is based on the native port of the Rust application on Windows. The effects of the optimisations on WebAssembly execution speed are touched on for reference.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-06-19"
                  datetime_mmmddyyyy="Jun 19, 2021"
                  source="medium.com"
                  title="VueJS + Rust + WebAssembly + WebWorker hosted on AWS S3, an example"
                  url="https://julien-decharentenay.medium.com/vuejs-rust-webassembly-webworker-hosted-on-aws-s3-an-example-7e917ff5edd2"
                >
This story describes the setup of a VueJS website, created using the Vue CLI, to run, via WebWorkers, WebAssembly compiled from Rust. The VueJS website is deployed and served from an AWS S3 bucket.
This story looks at how these technologies are put together to serve the fluid dynamics simulation engine and employed to compare native and WebAssembly speed.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-05-31"
                  datetime_mmmddyyyy="May 31, 2021"
                  source="medium.com"
                  title="Rust native vs WebAssembly execution speed� a comparison experiment using a fluid dynamics vortex particle simulation"
                  url="https://julien-decharentenay.medium.com/rust-native-vs-webassembly-execution-speed-a-comparison-experiment-using-a-fluid-dynamics-vortex-4b08f535cd9"
                >
                  This story compares WebAssembly and native execution speeds in the context of Fluid Dynamics, a computationally intensive task to predict the movement of fluids. It investigates whether a fluid dynamics simulation could be undertaken using WebAssembly in a web-browser with little execution speed penalty over native execution.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-04-12"
                  datetime_mmmddyyyy="Apr 12, 2021"
                  source="medium.com"
                  title="Fluid Simulation Using Vortex Particle"
                  url="https://julien-decharentenay.medium.com/diy-web-analytics-7a702f164a8d"
                >
                  This medium.com article looks at the implementation details
                  for a very basic vortex particle simulation engine in Rust. It
                  covers some of the mathematics associated with this method,
                  describes in broad terms its implementation in an
                  <a
                    href="https://github.com/juliendecharentenay/vortexParticleSimulation"
                    >open-source project</a
                  >, an presents a
                  <a href="https://youtu.be/rAbWaI0Yl6M">video</a>
                  of the simulation of the propagation of a vortex ring.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-03-17"
                  datetime_mmmddyyyy="Mar 17, 2021"
                  source="medium.com"
                  title="DIY Web Analytics"
                  url="https://julien-decharentenay.medium.com/diy-web-analytics-7a702f164a8d"
                >
                  This medium.com article looks at an alternative option to free
                  and commercial analytics tools to collate information on
                  website visitor. The option relies on AWS AppSync with some
                  glue JavaScript to capture and store the relevant information
                  in an AWS DynamoDB.<br />
                  The benefits of this combination is in leveraging AWS
                  infrastructure so that no lambdas, EC2, etc instances are
                  required; hence limiting costs.<br />
                  Visualisation and post-processing of collecting information is
                  not considered yet.
                </ThoughtArticle>
                <ThoughtArticle
                  datetime_yyyymmdd="2021-02-14"
                  datetime_mmmddyyyy="Feb 14, 2021"
                  source="medium.com"
                  title="Using Google Ads and a landing page to test the viability of an idea"
                  url="https://julien-decharentenay.medium.com/using-google-ads-and-a-landing-page-to-test-the-viability-of-an-idea-d09f6b59dc8b"
                >
                  This article posted on medium.com relates how Google Ads and a
                  landing page were used to evaluate the potential of
                  <a href="https://www.chartership.io">chartership.io</a>. It
                  shows how one can use this approach to investigate acquisition
                  costs and hence aspects of financial viabilities. It also
                  provides feedback on my first experience running a short
                  Google Ads campaign.
                </ThoughtArticle>

                <ThoughtArticle
                  datetime_yyyymmdd="2020-12-14"
                  datetime_mmmddyyyy="Dec 14, 2020"
                  source="red-eng.com"
                  title="OptaPlanner for RED Virtual Event"
                  url="https://www.red-eng.com/news/optaplanner-for-red-virtual-event/"
                >
                  This article posted on the news section of RED Engineering
                  Design Ltd website relates my experience using OptaPlanner to
                  solve a scheduling problem. During the pandemic, RED ran a
                  number of social events to foster interactions between
                  employees. The events were focused on grouping participants in
                  small group of 5 to 7 people that would take part in 15
                  minutes activities. OptaPlanner was used to allocate
                  participants in groups to maximise the potential for
                  interactions.
                </ThoughtArticle>

                <ThoughtArticle
                  datetime_yyyymmdd="2019-09-02"
                  datetime_mmmddyyyy="Sep 2-4, 2019"
                  source="IBPSA.org"
                  title="Parametric Study of Air Re-entrainment within Air-Cooled Chiller Compounds"
                  url="http://www.ibpsa.org/proceedings/BS2019/BS2019_210349.pdf"
                >
                  This paper was presented at the IBPSA Simulation 2019
                  conference held in September 2019. The objectif of the article
                  is to investigate the possibility of deriving a rule of thumb
                  to estimate the amount of air re-entrainment within an
                  air-cooled chiller compounds from early design parameters such
                  as total heat rejection, plant compound size. Over 200
                  Computational Fluid Dynamics simulations were undertaken with
                  varying air cooled chiller plant compound parameters to
                  establish a database of results that was then treated to
                  evaluate the dominant characteristics and postulate a possible
                  rule of thumbs.
                </ThoughtArticle>

                <ThoughtArticle
                  datetime_yyyymmdd="2018-05-18"
                  datetime_mmmddyyyy="May 18, 2018"
                  source="datacenterdynamic.com"
                  title="CFD modelling in the white space: why, and how?"
                  url="https://www.datacenterdynamics.com/en/opinions/cfd-modelling-in-the-white-space-why-and-how/"
                >
                  This article relates my experience undertaking Computational
                  Fluid Dynamics (CFD) in the data centre industry, particularly
                  data hall whitespace. After a review of the recommendations on
                  CFD in available standards, the article shows that, whilst CFD
                  is nominating numerously, there is a lack of detailed guidance
                  on how to apply CFD to white space.
                </ThoughtArticle>

              </div>
            </div>
          </div>
          <!-- End of Thoughts -->

          <!-- 
          Projects 
          Note: website project. Take screeshot using Chrome / Developer tools / toggle device toolbar / Set resolution to 800 x 800 / Take screenshot
         -->
          <div class="mt-4 py-16 bg-white overflow-hidden lg:py-24">
            <div
              class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl"
            >
              <div class="relative" id="projects">
                <h2
                  class="text-left text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
                >
                  Projects, current and past
                </h2>
              </div>

              <FeatureComponent
                :isLeft="true"
                :addDots="true"
                title="1-ml.com - Email privacy using mail masking"
                imageUrl="/images/www.1-ml.com.png"
                imageObjectPositionClass="object-left-top"
                url="https://www.1-ml.com"
              >
                This website provides an email masking service to stem the flow of emails from newsletters and services. The mail masking service provides you with a limitless number of email address that you can use when subscribing. You can then control these email addresses behavior so that your mailbox remain relevant to your changing needs. 
              </FeatureComponent>

              <FeatureComponent
                :isLeft="false"
                title="Video-mash.com - experimenting with videos and canvas manipulation"
                imageUrl="/images/www.video-mash.com_.png"
                imageObjectPositionClass="object-left-top"
                url="https://www.video-mash.com"
              >
                This website has been developed to experiment with videos, canvas manipulation, and ML segmentation models. The technology used is described in medium posts between October and December 2021. The project has been a lot of fun to develop and showcase - even though its application to something useful seems to be very limited.
I was looking at adding a functionality to allow one to present the weather - but was not able to be obtain the authorisation to reuse weather videos unfortunately...
              </FeatureComponent>

              <FeatureComponent
                :isLeft="true"
                title="CFD Webassembly"
                imageUrl="/images/cfd-webassembly.com_vpm_index.html.png"
                imageObjectPositionClass="object-left-top"
                url="https://www.cfd-webassembly.com"
              >
                This is another experiment drawing on the work from Dr M.J Gourlay describing the vortex method. But in this experiment, the solver is being re-written in Rust and compiled to WebAssembly as well as natively. A number of 2021 medium posts describe specific aspects of the implementation, including a comparison of native and WebAssembly execution speed. These posts have been the most popular ones - by number of views and reads - of the ones I made in 2021. I took a break from development after adding some visualisation due to a technical aspect slowing the solver down. I will be looking at solving this issue thanks to the pointers from <a href="https://medium.com/@qm3ster">Mihail Malostanidis</a> in <a href="https://medium.com/@qm3ster/you-may-want-to-try-using-shared-memory-to-communicate-between-the-simulation-and-the-render-thread-50d0f0adbc09">this response</a>.
              </FeatureComponent>

              <FeatureComponent
                :isLeft="false"
                title="WebAssembly port of Fluid Simulation for Video Game (part 3) [Dr M.J Gourlay]"
                imageUrl="/images/www.buildingphysicsonline.com_MjgIntelFluidDemo_webgl.html.png"
                imageObjectPositionClass="object-left-bottom"
                url="https://www.buildingphysicsonline.com/MjgIntelFluidDemo/webgl.html"
              >
                This is a port of the fluid simulation engine for video game
                developed by Dr Michael J Gourlay to WebAssembly. This exercise
                was primarily the opportunity for myself to get familiar with
                WebAssembly whilst doing something I enjoy... fluid dynamics...
                The porting of the solver itself was straightforward. The
                porting of the rendering was not... <br />
                Information on the Fluid Simulation for Games engine, including
                source code, is available at
                https://software.intel.com/en-us/articles/fluid-simulation-for-video-games-part-3/<br />
                This exercise was both fun and reasonably successful with the
                page mentioned on
                <a href="https://wasmweekly.news/">WebAssembly Weekly</a> and
                <a href="https://blog.scottlogic.com/">Scott Logic's Blog</a>.
              </FeatureComponent>

              <FeatureComponent
                :isLeft="true"
                title="Building Physics Online"
                imageUrl="/images/www.buildingphysicsonline.com_.png"
                url="https://www.buildingphysicsonline.com"
              >
                The term building physics, or building engineering physics, has
                been recently defined. It covers the building services
                engineering field dedicated to the investigation of energy
                efficiency of old and new buildings. This skill-set was
                traditionally encompassed within sustainability teams, but
                differentiated by the use of computational modelling. The
                discipline addresses several different areas in building
                performance including: air movement, thermal performance,
                moisture, energy, acoustics, light, climate and biology,
                typically through modelling based quantification. This website
                promotes building physics resources, online tools, apps,
                calculator to facilitate the dissemination of knowledge and good
                practices
              </FeatureComponent>

              <FeatureComponent
                :isLeft="false"
                title="ShareML.io"
                imageUrl="/images/www.shareml.io_.png"
              >
                Project no longer supported and live.<br/>
                This website promotes the discovery and sharing of object
                detection machine learning models. Through this website, I am
                promoting a number of trained object detection models based on
                the TensorFlow framework including one trained to recognise
                Wally in "Where is Wally?" books as well as more general models
                trained on generic and publically available datasets. The
                website allows anyone to register and upload object detection
                models. These models should be able to be used directly from the
                website using TensorFlow.js - but this feature is currently
                (17th February 2021) broken - but can also be loaded via an
                Android app available on the Google Play store.
              </FeatureComponent>

              <FeatureComponent
                :isLeft="true"
                :addDots="true"
                title="EtikLogs"
                imageUrl="/images/etiklogs.com_.png"
              >
                Project no longer supported and live.<br/>
                I used Google Analytics to monitor the number of visitors and
                how they came to my websites. With the backlash on privacy, I
                looked at how to make something to replace it for my use. And so
                was EtikLogs created - thanks Davide for the name. It is a rough
                but working product - but in need of some refreshment and
                dashboard presentations. There are many products out there that
                fits this purpose, so I probably won't be focusing on it any
                time soon.
              </FeatureComponent>
            </div>
          </div>
          <!-- End of Projects -->
          <div class="my-12"></div>
        </div>
      </main>
      <FooterSection />
    </div>
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection-simple.vue";
import FooterSection from "@/components/FooterSection.vue";
import NavBar from "@/components/NavBar-simple.vue";
import ThoughtArticle from "@/components/ThoughtArticle.vue";
import FeatureComponent from "@/components/FeatureComponent.vue";

export default {
  name: "App",
  components: {
    HeroSection,
    FooterSection,
    NavBar,
    ThoughtArticle,
    FeatureComponent,
  },
};
</script>
