<template>
  <div class="">
    <div v-if="addDots && isLeft">
      <svg
        class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>
    </div>

    <div v-if="addDots && !isLeft">
      <svg
        class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
    </div>

    <div v-if="isLeft">
      <!-- Feature component with text on left and image on the right -->
      <div
        class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
      >
        <div class="relative">
          <h3
            class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl"
          >
            {{ title }}
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            <slot></slot>
          </p>
          <div class="mt-3" v-if="url != null">
            <a
              :href="url"
              class="text-base font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Visit {{ url }}
            </a>
          </div>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg
            class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
            width="784"
            height="404"
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="784"
              height="404"
              fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
            />
          </svg>
          <img
            class="relative mx-auto rounded-lg object-none"
            :class="imageObjectPositionClass"
            width="500"
            height="500"
            :src="imageUrl"
            alt=""
            v-if="imageUrl != null"
          />
        </div>
      </div>
    </div>

    <div v-if="!isLeft">
      <!-- Feature component with image on left and text on the right -->

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div
          class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"
        >
          <div class="lg:col-start-2">
            <h3
              class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl"
            >
              {{ title }}
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              <slot></slot>
            </p>
            <div class="mt-3" v-if="url != null">
              <a
                :href="url"
                class="text-base font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Visit {{ url }}
              </a>
            </div>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg
              class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
              />
            </svg>
            <img
              class="relative mx-auto rounded-lg object-none"
              :class="imageObjectPositionClass"
              width="500"
              height="500"
              :src="imageUrl"
              alt=""
              v-if="imageUrl != null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureComponent",
  props: {
    title: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: false,
      default: null
    },
    imageObjectPositionClass: {
      type: String,
      required: false,
      default: "object-left-top"
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    isLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    addDots: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
