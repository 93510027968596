<template>
  <div>
    <p class="flex space-x-1 text-sm text-gray-500">
      <time :datetime="datetime_yyyymmdd">{{ datetime_mmmddyyyy }}</time>
      <span aria-hidden="true">&middot;</span>
      <span>{{ source }}</span>
    </p>
    <a :href="url" class="mt-2 block">
      <p class="text-xl font-semibold text-gray-900">
        {{ title }}
      </p>
      <p class="mt-3 text-base text-gray-500">
        <slot></slot>
      </p>
    </a>
    <div class="mt-3">
      <a
        :href="url"
        class="text-base font-semibold text-indigo-600 hover:text-indigo-500"
      >
        Read full story
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThoughtArticle",
  props: {
    datetime_yyyymmdd: String,
    datetime_mmmddyyyy: String,
    source: String,
    title: String,
    url: String
  }
};
</script>
