<!-- Footer section extracted from https://tailwindui.com/components/marketing/page-examples/landing-pages#component-c574f863382921bf9af9611c55f30ecc -->
<template>
  <div class="footer_section">
    <!-- Footer section -->
    <footer class="mt-12 bg-gray-900 sm:mt-6">
      <div
        class="mx-auto max-w-md py-6 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
      >
        <p class="text-center text-base text-gray-400">
          &copy; 2021 Julien de Charentenay
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
  props: {}
};
</script>
